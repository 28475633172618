import { useEffect, useState } from 'react';
import { Button } from 'baseui/button';
import { Input } from 'baseui/input';
import './LoginForm.css';

const params = new URLSearchParams(document.location.search);

export function LoginForm({ setLoggedIn, setIsOwner }) {
  const [pw, setPw] = useState('');
  const [code, setCode] = useState(params.get('code') || '');
  const [firstTime, setFirst] = useState(true);

  useEffect(() => {
    fetch('/api/v1/isAuth').then(res => {
      if (res.status === 200) {
        setLoggedIn(true);
      }

      if (res.headers.get('content-type') === 'application/json') {
        return res.json().then(res => {
          setIsOwner(res.isOwner);
        });
      }
    });
  }, [setLoggedIn, setIsOwner]);

  useEffect(() => {
    if (params.get('code')) {
      logIn(false);
    }
  });

  function logIn(usePw) {
    const bod = {};
    if (usePw) {
      bod.pw = pw;
    } else {
      bod.code = code.trim().toUpperCase();
    }

    fetch('/api/v1/login', {
      method: 'POST',
      body: JSON.stringify(bod),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => {
        if (res.status >= 400) {
          alert('Login Failed! Try again.');
          return;
        }
        setIsOwner(res.isOwner);
        setLoggedIn(true);
      })

      .catch(() => {
        if (firstTime) {
          setFirst(false);
        } else {
          alert('Login Failed! Try again.');
        }
      });
  }

  return (
    <main className='login-form'>
      <Input
        value={code}
        onChange={e => setCode(e.target.value)}
        placeholder='Enter Share Code'
      />
      <Button onClick={() => logIn(false)}>Submit</Button>
      <h3>Or</h3>
      <Input
        value={pw}
        onChange={e => setPw(e.target.value)}
        placeholder='Enter Password'
      />
      <Button onClick={() => logIn(true)}>Submit</Button>
    </main>
  );
}
