import './App.css';
import { useState, useEffect } from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { Button } from 'baseui/button';
import { Select } from 'baseui/select';
import { useWindowWidth } from '@react-hook/window-size';

import Form from './components/Form';
import Data from './components/Data';
import { LoginForm } from './components/LoginForm';

const engine = new Styletron();

function App() {
  const [modalOpen, setModal] = useState(true);
  const [data, setData] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [dateRange, setDateRange] = useState([
    { label: 'Last three days of food', id: 3 },
  ]);
  const [refetch, setRefetch] = useState(true);
  const width = useWindowWidth();

  const options = [
    { label: 'Last three days of food', id: 3 },
    { label: 'Last week of food', id: 7 },
    { label: 'Last two weeks of food', id: 14 },
  ];

  const triggerRefetch = () => setRefetch(!refetch);

  function logOut() {
    fetch('/api/v1/logout').then(() => setLoggedIn(false));
  }

  useEffect(() => {
    setTimeout(() => {
      Array.from(document.querySelectorAll('.disable-input input')).forEach(
        el => {
          el.setAttribute('inputmode', 'none');
          el.setAttribute('readonly', 'readonly');
        },
      );
    }, 1000);
  });

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        {loggedIn ? (
          <>
            <Form
              modalOpen={modalOpen}
              setModal={setModal}
              data={data}
              triggerRefetch={triggerRefetch}
            />
            <main>
              <div
                id='header-form'
                style={width < 700 ? { flexDirection: 'column' } : {}}
              >
                <div className='disable-input'>
                  <Select
                    options={options}
                    value={dateRange}
                    onChange={params => setDateRange(params.value)}
                    placeholder='Select days back to view'
                    searchable={false}
                    clearable={false}
                  />
                </div>
                <div className='btn-container'>
                  <Button
                    onClick={logOut}
                    style={
                      width < 700
                        ? {
                            marginTop: '10px',
                            backgroundColor: '#b92935',
                          }
                        : { backgroundColor: '#b92935' }
                    }
                  >
                    Log Out
                  </Button>
                  {isOwner ? (
                    <div>
                      <Button
                        onClick={() => setModal(true)}
                        style={
                          width < 700
                            ? { width: '100%', marginTop: '10px' }
                            : {}
                        }
                      >
                        Add Food Log
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
              <Data
                setModal={setModal}
                setData={setData}
                isOwner={isOwner}
                dateRange={dateRange}
                triggerRefetch={triggerRefetch}
                refetch={refetch}
              />
            </main>
          </>
        ) : (
          <LoginForm setLoggedIn={setLoggedIn} setIsOwner={setIsOwner} />
        )}
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
