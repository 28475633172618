import { useState, useEffect } from 'react';
import { Modal, SIZE, ROLE, ModalButton } from 'baseui/modal';
import { TimePicker } from 'baseui/timepicker';
import { DatePicker } from 'baseui/datepicker';
import { Input } from 'baseui/input';
import { Textarea } from 'baseui/textarea';
import { FileUploader } from 'baseui/file-uploader';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import './Form.css';
import { sub } from 'date-fns';
import { getHours, setHours, setMinutes } from 'date-fns';
import { Select } from 'baseui/select';

const oneWeekAgo = sub(new Date(), { weeks: 1 });

export default function Form({ modalOpen, setModal, data, triggerRefetch }) {
  const [id, setId] = useState(null);
  const [fileName, setFileName] = useState('');
  const [img, setImg] = useState('');
  const [isUploading, setUploading] = useState(false);
  const [date, setDate] = useState([new Date()]);
  const [time, setTime] = useState(new Date());
  const [meal, setMeal] = useState('');
  const [food, setFood] = useState('');
  const [triggers, setTriggers] = useState(data.triggers || '');
  const [fullnessBefore, setFullnessBefore] = useState(null);
  const [fullnessAfter, setFullnessAfter] = useState(null);
  const [overrideDate, setOverride] = useState(true);
  const [photoStatus, setPhotoStatus] = useState(false);

  useEffect(() => {
    setId(data.id);
    setFileName(data.fileName);
    setImg(
      data.fileName
        ? `https://storage.googleapis.com/food-log-photos/${data.fileName}`
        : '',
    );
    if (data.date) setDate([new Date(data.date)]);
    if (data.date) setTime(new Date(data.date));
    setMeal(data.meal);
    setFood(data.food);
    setTriggers(data.triggers);
    const { fullnessBefore, fullnessAfter } = data;
    setFullnessBefore(
      fullnessBefore
        ? [
            {
              label: fullnessBefore,
              id: fullnessBefore,
            },
          ]
        : null,
    );
    setFullnessAfter(
      fullnessAfter
        ? [
            {
              label: fullnessAfter,
              id: fullnessAfter,
            },
          ]
        : null,
    );
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      Array.from(document.querySelectorAll('.disable-input input')).forEach(
        el => {
          el.setAttribute('inputmode', 'none');
          el.setAttribute('readonly', 'readonly');
        },
      );
    }, 1000);
  });

  function clearAll() {
    setModal(false);
    setId(null);
    setImg(null);
    setDate([new Date()]);
    setTime(new Date());
    setMeal('');
    setFood('');
    setTriggers('');
    setFileName('');
    setPhotoStatus(false);
  }

  function postPhoto(photo, id) {
    setUploading(true);
    let formData = new FormData();

    formData.append('photo', photo);
    if (id) formData.append('id', id);
    fetch('/api/v1/upload', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (res.status >= 400) {
          alert(
            'File did not upload. Please ensure your file is a jpg or png.',
          );
          setUploading(false);
          return;
        }
        return res.json();
      })
      .then(({ fileName, id }) => {
        setFileName(fileName);
        setId(id);
        setImg(`https://storage.googleapis.com/food-log-photos/${fileName}`);
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  }

  function postAll() {
    let dateToSend = date[0];
    // get minutes and hours from time
    const hours = getHours(time);
    const minutes = time.getMinutes();
    // apply those minutes and hours to date
    dateToSend = setHours(dateToSend, hours);
    dateToSend = setMinutes(dateToSend, minutes);
    // convert time to milliseconds
    const dateInMillis = dateToSend.getTime();

    fetch('/api/v1/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        meal,
        food,
        fileName: fileName || '',
        triggers,
        date: dateInMillis,
        fullnessBefore: fullnessBefore ? fullnessBefore[0]?.label : null,
        fullnessAfter: fullnessAfter ? fullnessAfter[0]?.label : null,
      }),
    })
      .then(res => {
        if (res.status >= 400) {
          alert("Didn't save, try again.");
          return;
        }

        clearAll();
        triggerRefetch();
      })
      .catch(() => alert("Didn't save, try again."));
  }

  return (
    <Modal
      onClose={() => {
        setModal(false);
        clearAll();
      }}
      closeable
      isOpen={modalOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: () => ({ padding: '10px' }),
        },
      }}
    >
      <div className='photo-container'>
        {img ? (
          <img src={img} alt='food' className='img' />
        ) : (
          <>
            <FileUploader
              progressMessage={isUploading ? 'Uploading' : ''}
              onDrop={acceptedFiles => {
                const file = acceptedFiles[0];
                const { type, lastModified } = file;
                if (!['image/png', 'image/jpeg'].includes(type)) {
                  alert('Please only upload jpg, jpeg, or png files.');
                  return;
                }

                if (overrideDate) {
                  const timeOfUpload = new Date(lastModified);
                  if (timeOfUpload.getTime() > oneWeekAgo) {
                    setTime(timeOfUpload);
                    setDate([timeOfUpload]);
                  } else {
                    setPhotoStatus(true);
                  }
                }

                postPhoto(file);
              }}
            />
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.left}
              checked={overrideDate}
              onChange={() => setOverride(!overrideDate)}
            >
              Override time set below with photo's capture time
            </Checkbox>
          </>
        )}
      </div>
      {photoStatus ? (
        <p style={{ color: 'red', padding: '0 40px', textAlign: 'center' }}>
          Photo creation time was more than one week ago. Time below was not
          overwritten.
        </p>
      ) : null}
      <div className='input-form'>
        <div className='date disable-input'>
          <div>
            <h4>Time</h4>
            <TimePicker
              value={time}
              onChange={time => setTime(time)}
              minTime={new Date('2022-12-11T18:30:00.000Z')}
            />
          </div>
          <div>
            <h4>Day</h4>
            <DatePicker
              value={date}
              onChange={({ date }) =>
                setDate(Array.isArray(date) ? date : [date])
              }
              minDate={sub(new Date(), { weeks: 2 })}
              maxDate={new Date()}
              formatString='LLL do'
            />
          </div>
        </div>
        <h4>Meal</h4>
        <Input
          value={meal}
          onChange={e => setMeal(e.target.value)}
          placeholder='Meal'
          clearOnEscape
        />
        <h4>Food Description</h4>
        <Textarea
          value={food}
          onChange={e => setFood(e.target.value)}
          placeholder='Food Description'
          clearOnEscape
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4>Fullness</h4>
          <small> - 1 is starving, 10 is stuffed</small>
        </div>
        <div className='before-after-row disable-input'>
          <h5 className='before-after-label'>Before</h5>
          <Select
            value={fullnessBefore}
            onChange={params => setFullnessBefore(params.value)}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => ({
              label: num,
              id: num,
            }))}
          />
          <h5 className='before-after-label' style={{ marginLeft: '20px' }}>
            After
          </h5>
          <Select
            value={fullnessAfter}
            onChange={params => setFullnessAfter(params.value)}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => ({
              label: num,
              id: num,
            }))}
          />
        </div>
        <h4>Hunger/Fullness Notes</h4>
        <Textarea
          value={triggers}
          onChange={e => setTriggers(e.target.value)}
          placeholder='Triggers Hunger/Fullness'
          clearOnEscape
        />
        <ModalButton
          onClick={postAll}
          style={{ float: 'right' }}
          disabled={
            isUploading || (data.fileName && (!meal || !food || !triggers))
          }
        >
          Save
        </ModalButton>
      </div>
    </Modal>
  );
}
