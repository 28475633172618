import { useEffect, useState } from 'react';
import './Data.css';
import { Card } from 'baseui/card';
import { Button, KIND } from 'baseui/button';
import { useWindowWidth } from '@react-hook/window-size';
import { format, setHours, setMinutes, sub } from 'date-fns';

function sortAndFilterDate(data, dateRange) {
  let daysBack = sub(new Date(), { days: dateRange });
  daysBack = setHours(daysBack, 0);
  daysBack = setMinutes(daysBack, 0);
  const filtered = data.filter(d => new Date(d.date) > daysBack);
  filtered.sort((a, b) => b.date - a.date);
  return filtered;
}

export default function Data({
  setModal,
  setData,
  dateRange,
  isOwner,
  triggerRefetch,
  refetch,
}) {
  const [rawLogs, setRawLogs] = useState([]);
  const width = useWindowWidth();

  useEffect(() => {
    fetch('/api/v1/logs')
      .then(d => d.json())
      .then(setRawLogs);
  }, [refetch]);

  const logs = sortAndFilterDate(rawLogs, dateRange[0].id);

  if (!logs.length) {
  }

  const noLogs = (
    <p>
      No food logs found in this range. Add a food log or change the date range
    </p>
  );

  return (
    <div className='card-container'>
      <div className='cards'>
        {logs.length
          ? logs.map(log =>
              CardLog({
                data: log,
                setModal,
                setData,
                width,
                isOwner,
                triggerRefetch,
              }),
            )
          : noLogs}
      </div>
    </div>
  );
}

function CardLog({ data, setModal, setData, width, isOwner, triggerRefetch }) {
  const {
    date,
    food,
    id,
    meal,
    triggers,
    fileName,
    fullnessBefore,
    fullnessAfter,
  } = data;

  const link = `https://storage.googleapis.com/food-log-photos/${fileName}`;

  const displayTime = format(date, 'h:mm aaa');
  const displayDate = format(date, 'MMM do');
  const flexDirection = width < 700 ? 'column' : 'row';

  function edit() {
    setData(data);
    setModal(true);
  }

  function deleteLog() {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm(
      'Are you sure you want to delete this log? This action cannot be undone.',
    );
    if (!result) return;
    fetch('/api/v1/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        fileName,
      }),
    })
      .then(res => {
        if (res.status >= 400) {
          alert('An error occurred. File was not deleted');
        }
      })
      .then(triggerRefetch);
  }

  return (
    <Card key={id} style={{ marginBottom: '20px' }}>
      <div className='card' style={{ flexDirection }}>
        <div
          className='img-container'
          style={{ margin: width < 700 ? 'auto' : null }}
        >
          {fileName ? (
            <img
              src={link}
              alt='food'
              className='img'
              style={
                width < 700 ? { maxHeight: '500px', maxWidth: '100%' } : {}
              }
            />
          ) : (
            <div className='no-photo'>No Photo</div>
          )}
        </div>
        <div className='info-container'>
          <div className='date' style={{ flexDirection }}>
            <h2>{`${meal || 'Meal'} - ${displayTime}`}</h2>
            <h2>{displayDate}</h2>
          </div>
          <h3>Food Description</h3>
          <div>{food}</div>
          <h3>Fullness Level</h3>
          <div className='fullness-row'>
            <h4>
              Before: <span>{fullnessBefore}</span>
            </h4>
            <h4>
              After: <span>{fullnessAfter}</span>
            </h4>
          </div>
          <h3>Hunger/Fullness Notes</h3>
          <div>{triggers}</div>
          {isOwner ? (
            <div className='btn-container'>
              <Button
                onClick={deleteLog}
                kind={KIND.secondary}
                style={{ backgroundColor: '#b92935', color: 'white' }}
              >
                Delete
              </Button>
              <Button onClick={edit}>Edit</Button>
            </div>
          ) : null}
        </div>
      </div>
    </Card>
  );
}
